import { texts } from '../../texts.js';

const languageLocale = navigator.language;

const intro = document.querySelector('.hero__text-intro');
const heroStory = document.querySelector('.hero__text-story')
const bioTitle = document.querySelector('.bio__text-title')
const bioStory = document.querySelector('.bio-story')


if(languageLocale.split('-')[0] !== 'es'){
  intro.innerHTML = texts.intro.english;
  heroStory.innerHTML = texts.hero.english;
  bioTitle.innerHTML = texts.about.english;
  bioStory.innerHTML = texts.bio.english;
}else{
  intro.innerHTML = texts.intro.spanish;
  heroStory.innerHTML = texts.hero.spanish;
  bioTitle.innerHTML = texts.about.spanish;
  bioStory.innerHTML = texts.bio.spanish;
}


document.addEventListener('DOMContentLoaded', function(){

  document.querySelector('.hero__text-name').classList.add('visible')
})
