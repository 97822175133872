export const texts =  {
  intro: {
    spanish: "Me llamo",
    english: "My name is"
  },
  "hero": {
    spanish: "Y estoy emocionada de invitarte a sumergirte en mi mundo artístico.</p>Te invito a explorar mi portafolio, donde encontrarás como experimento con los colores, las texturas y la narrativa visual que define mi arte.</p>Cada pincelada representa mi amor por la pintura, y cada obra es una ventana a mi mundo interior y lo que me rodea.</p>Únete a mí en este viaje y sumérgete en las historias que cuento en cada lienzo.</p>",
    english: "And I am excited to invite you to dive into my artistic world.</p>I invite you to explore my portfolio, where you will see how I experiment with colors, textures, and the visual narrative that defines my art.</p>Each brushstroke represents my love for painting, and each piece is a window into my inner world and what surrounds me.</p>Join me on this journey and immerse yourself in the stories I tell on each canvas</p>"
  },
  "about": {
    spanish: "Sobre mí",
    english: "About me"
  },
  "bio": {
    spanish: "<p>Nací en Los Angeles, California, Estados Unidos, y mi viaje artístico comenzó en el Oregon College of Art and Craft, donde me especialicé en artes textiles.</p>Después de contraer matrimonio, decidí mudarme a las Islas Canarias, la hermosa tierra natal de mi esposo.</p>Mi pasión por el arte ha evolucionado a lo largo de los años, y en la última etapa de mi trayectoria, he descubierto un profundo amor por la pintura.</p>Desde la ventana de mi pequeño apartamento desarrollo mi estilo mientras reflejo en mis pinturas a mis seres queridos y escenas de mi ciudad.</p></p>",
    english: "I was born in Los Angeles, California, United States, and my artistic journey began at the Oregon College of Art and Craft, where I specialized in textile arts.</p>After getting married, I decided to move to the Canary Islands, the beautiful homeland of my husband.</p>My passion for art has evolved over the years, and in the latest phase of my career, I have discovered a deep love for painting.</p>From the window of my small apartment, I continue to develop my style while reflecting on my loved ones and scenes from my city.</p>"
  }
}